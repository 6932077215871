<template>
    <div>
        <el-form label-position="right" :rules='rules' label-width="80px" ref='formInfo' inline :model="formInfo"
            :size='tabsize'>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label="职位名称" prop='jname'>
                        <el-input v-model="formInfo.jname"></el-input>
                    </el-form-item>

                </el-col>
                <!-- <el-col :span='12'>
                    <el-form-item label="职位顺序" prop='inx'>
                        <el-input-number v-model="formInfo.inx" controls-position="right" :min="0" :max="99">
                        </el-input-number>
                    </el-form-item>
                </el-col> -->

            </el-row>
            <el-row>
                <!-- <el-col :span='12'>
                    <el-form-item label="职位编码" prop='email'>
                        <el-input v-model="formInfo.jname"></el-input>
                    </el-form-item>
                </el-col> -->
            </el-row>
            <el-row>
                <el-form-item label="备注" class="remark">
                    <el-input v-model="formInfo.remark"></el-input>
                </el-form-item>
            </el-row>
            <p class="btns">
                <el-button type="primary" :size='tabsize' @click="submitForm('formInfo')">提交</el-button>
            </p>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                rules: {
                    jname: [{
                        required: true,
                        message: '请输入部门名称',
                        trigger: 'blur'
                    }],
                    inx: [{
                        required: true,
                        message: '请输入顺序',
                        trigger: 'blur'
                    }],
                },
            }
        },
        props: ['formInfo'],
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$parent.$parent.loading = true
                        this.$parent.$parent.formShow = false
                        let url
                        let str
                        if (this.formInfo.id) {
                            url = this.$api.job.edit
                            str = '修改'
                        } else {
                            url = this.$api.job.add
                            str = '添加'
                        }
                        url(this.formInfo).then(res => {
                            this.$parent.$parent.loading = false
                            this.$parent.$parent.updateList()
                            if (res.status == 1) {
                                this.$notify.success({
                                    title: 'Info',
                                    message: str + '成功',
                                    showClose: false,
                                    duration: 2000,
                                });
                            } else {
                                this.$notify.error({
                                    title: 'Info',
                                    message: res.msg,
                                    showClose: false,
                                    duration: 2000,
                                });
                            }
                        }).catch((error) => {
                            console.log(error);
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                })
            },
        },
        // 监听尺寸按钮变化 改变表格大小
        computed: {
            tabsize() {
                return this.$store.state.tabsize
            }
        },
    }
</script>

<style scoped>
    .btns {
        text-align: center;
    }

    /deep/.el-form-item__content {
        width: 190px;
    }

    /deep/.el-input__inner {
        text-align: left;
    }

    .remark {
        width: 100%;
    }

    .remark/deep/.el-form-item__content {
        width: calc(100% - 92px);
    }

    .el-input-number {
        width: 100%;
    }
</style>